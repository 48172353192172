@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  background-color: #180b3b !important;
  /* background-image: url("./images/default_bg.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: auto;
  position: relative;
  scroll-behavior: smooth;
}

.demoScroll {
  overflow-y: auto;
}

.sc5::-webkit-scrollbar {
  width: 5px;
  height: 15px;
}
.sc5::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
.sc5::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #00aeff, #a68eff);
  border-radius: 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
}
